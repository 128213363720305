import React, { lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Loader from "./components/Loader";


// Lazily loaded components
const CodeConclave = lazy(() => import("./components/CodeConclave"));
const AuthorProblemPage = lazy(() => import("./components/AuthorProblemPage"));
const EditProblemPage = lazy(() => import("./components/EditProblemPage"));
const AuthorProfile = lazy(() => import("./components/AuthorProfile"));
const AuthorsLeaderboard = lazy(() => import("./components/AuthorsLeaderboard"));
const ProblemCodePage = lazy(() => import("./components/ProblemCodePage"));
const AuthorMCQPage = lazy(() => import("./components/AuthorMCQPage"));
const AllContestsPage = lazy(() => import("./components/AllContestsPage"));
const ViewMCQPage = lazy(() => import("./components/ViewMCQPage"));
const EditMCQPage = lazy(() => import("./components/EditMCQPage"));
const ContestDetailsPage = lazy(() => import("./components/ContestDetailsPage"));
const ContestPage = lazy(() => import("./components/ContestPage"));
const TestEndedSuccessfully = lazy(() => import("./components/TestEndedSuccessfully"));
const CreateContest = lazy(() => import("./components/CreateContest"));
const EditContest = lazy(() => import("./components/EditContest"));
const CreateAssessment = lazy(() => import("./components/CreateAssessment"));
const AllAssessmentsPage = lazy(() => import("./components/AllAssessmentsPage"));
const UpdateSections = lazy(() => import("./components/UpdateSections"));
const UpdateSectionMcq = lazy(() => import("./components/UpdateSectionMcq"));
const UpdateSectionCoding = lazy(() => import("./components/UpdateSectionCoding"));
const AssessmentHomePage = lazy(() => import("./components/AssessmentHomePage"));
const SectionPage = lazy(() => import("./components/SectionPage"));
const ManageTestcases = lazy(() => import("./components/ManageTestcases"));
const UpdateTaskProblems = lazy(() => import("./components/UpdateTaskProblems"));
const MyTasks = lazy(() => import("./components/MyTasks"));
const TaskCodePage = lazy(() => import("./components/TaskCodePage"));
const TaskLeaderBoard = lazy(() => import("./components/TaskLeaderBoard"));
const NotFoundPage = lazy(() => import("./components/NotFoundPage"));
const CodingHandles = lazy(() => import("./components/CodingHandles"));
const Login = lazy(() => import("./components/Login"));
const Profile = lazy(() => import("./components/Profile"));
const HomePage = lazy(() => import("./components/HomePage"));
const LeaderBoard = lazy(() => import("./components/LeaderBorad"));
const Users = lazy(() => import("./components/Users"));
const Admin = lazy(() => import("./components/Admin"));
const College = lazy(() => import("./components/College"));
const Batch = lazy(() => import("./components/Batch"));
const CreateBatch = lazy(() => import("./components/CreateBatch"));
const AboutPage = lazy(() => import("./components/AboutPage"));
const ContactPage = lazy(() => import("./components/ContactPage"));
const Courses = lazy(() => import("./components/Courses"));
const PlagiarismChecker = lazy(() => import("./components/PlagiarismReport"));
const CourseEnrollments = lazy(() => import("./components/CourseEnrollments"));
const CodingAssessmentPage = lazy(() =>
  import("./components/CodingAssessmentPage")
);
const ContestCalender=lazy(()=>import("./components/ContestCalender"))
const AllProblemsPage=lazy(()=>import("./components/AllProblemsPage"))
const AllMCQsPage=lazy(()=>import('./components/AllMCQsPage'))
const AllTasksPage=lazy(()=>import('./components/AllTasks'))
const CreateTask = lazy(() => import("./components/CreateTask"))
const EditAssessment = lazy(() => import("./components/EditAssessment"));
const CodingProblemPage = lazy(() => import("./components/CodingProblemPage"));
function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route exact path="/home" element={<HomePage />} />
          <Route exact path="/about" element={<AboutPage />} />
          <Route exact path="/contact" element={<ContactPage />} />
          <Route exact path="/courses" element={<Courses />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/profile" element={<Profile />} />
          <Route exact path="/leaderboard" element={<LeaderBoard />} />
          <Route exact path="/users" element={<Users />} />
          <Route exact path="/admin" element={<Admin />} />
          <Route exact path="/college" element={<College />} />
          <Route exact path="/batch" element={<Batch />} />
          <Route exact path="/create/batch" element={<CreateBatch />} />
          <Route exact path="/update/handles" element={<CodingHandles />} />
          <Route exact path="/plagiarism/report/vjudge" element={<PlagiarismChecker />} />
          <Route exact path="/course/enrollments" element={<CourseEnrollments />} />
          <Route exact path="/codeconclave" element={<CodeConclave />} />
          <Route exact path="/author/problem" element={<AuthorProblemPage />} />
          <Route exact path="/create/contest" element={<CreateContest />} />
          <Route exact path="/problem/:slug" element={<ProblemCodePage />} />
          <Route exact path="/assessment/:slug1/:id/coding/:slug" element={<CodingProblemPage />} />
          <Route exact path="/edit/problem/:id" element={<EditProblemPage />} />
          <Route exact path="/author/profile/:id" element={<AuthorProfile />} />
          <Route exact path="/authors/leaderboard" element={<AuthorsLeaderboard />} />
          <Route exact path="/author/mcq" element={<AuthorMCQPage />} />
          <Route exact path="/contests" element={<AllContestsPage />} />
          <Route exact path="/mcq/:id" element={<ViewMCQPage />} />
          <Route exact path="/contest/:slug" element={<EditContest />} />
          <Route exact path="/edit/mcq/:id" element={<EditMCQPage />} />
          <Route exact path="/contest/preview/:slug" element={<ContestDetailsPage />} />
          <Route exact path="/contest/test/run" element={<ContestPage />} />
          <Route exact path="/test/ended" element={<TestEndedSuccessfully />} />
          <Route exact path="/create/assessment" element={<CreateAssessment />} />
          <Route exact path="/all/assessments" element={<AllAssessmentsPage />} />
          <Route exact path="/assessment/update/:id" element={<UpdateSections />} />
          <Route exact path="/assessment/update/:id/section/mcq/:sectionId" element={<UpdateSectionMcq />} />
          <Route exact path="/assessment/update/:id/section/code/:sectionId" element={<UpdateSectionCoding />} />
          <Route exact path="/assessment/:slug" element={<AssessmentHomePage />} />
          <Route exact path="/assessment/:slug/:sectionId" element={<SectionPage />} />
          <Route exact path="/problem/testcase/:problemSlug" element={<ManageTestcases />} />
          <Route exact path="/task/update/problems/:taskSlug" element={<UpdateTaskProblems />} />
          <Route exact path="/mytasks/:taskSlug" element={<MyTasks />} />
          <Route exact path="/mytasks/:taskSlug/problem/:slug" element={<TaskCodePage />} />
          <Route exact path = "/assessment/editassessment/:id" element={<EditAssessment />} />
          {/* Catch-all route for 404 Not Found page */}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
